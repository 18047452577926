<template>
  <div class="inter-wrapper">
    <!-- 判斷是HG然後台新PAY -->
    <div v-if="payment === 'tscb' && qrcodeval === 'none'" class="qrcodearea">
      <!-- //CreditCard -->
      <div class="ckpaid-wrapper">
        <img src="@/assets/images/CreditCard.png" width="240" height="auto">
        <div class="snippet" data-title=".dot-flashing">
          <div class="stage">
            <div class="dot-flashing" />
          </div>
        </div>
        <div class="confirm-text">
          系統付款確認中
        </div>
        <div class="btn-group-bottom btnarea">
          <button class="btn btn-next" type="button" @click="checkpayment">
            我已完成付款
          </button>
        </div>
        <div class="warning-text">
          <p>
            * 完成支付後，請您耐心等待系統確認訂單或點擊【我已完成付款】按鈕
          </p>
          <p>* 請勿任意關閉或跳出頁面</p>
          <p>* 請確認是否安裝台新APP，若無安裝請您返回上一頁</p>
        </div>
      </div>
    </div>
    <div v-else-if="checkPlatform() === 'web'" class="qrcodearea">
      <qrcode-vue :value="qrcodeval" :size="size" level="H" />
      <span>請開啟APP掃描QRCODE</span>
      <div class="btn-group-bottom btnarea">
        <button class="btn btn-next" type="button" @click="checkpayment">
          我已完成付款
        </button>
      </div>
    </div>
    <div v-else class="qrcodearea">
      APP支付...
      <button class="btn btn-next" @click="backtoh()">
        返回首頁
      </button>
    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import { getOrderstatus } from '@/api/payment'
import { showNotice, getStoreCode, checkPlatform } from '@/utils/tool'
export default {
  name: 'Qrcode',
  components: {
    QrcodeVue
  },
  data() {
    return {
      size: 300,
      checktimer: false,
      apicount: 0,
      needcheckcount: 30,
      token: null,
      perchecktime: 10000
    }
  },
  computed: {
    qrcodeval() {
      return this.$route.params.code
    },
    payment() {
      return this.$route.params.payment
    },
    id() {
      return this.$route.params.id
    },
    icashpayapp() {
      return this.$store.getters.icashpayapp
    }
  },
  created() {
    // if login and in icash app call their app js todo payment
    if (getStoreCode() === 'icashpay' && checkPlatform() !== 'web') {
      try {
        const urlParams = new URLSearchParams(this.icashpayapp)
        let tradeToken = urlParams.get('Value')
        if (checkPlatform() === 'android') {
          tradeToken = encodeURIComponent(tradeToken)
        }
        window.iCashJSContext.call('OpenWebActivity', tradeToken, '3tgds')
      } catch (error) {
        console.log(error)
      }
    } else {
      // HG APP 使用tscb 台新支付
      if (this.payment === 'tscb' && this.qrcodeval === 'none') {
        // 檢查20分鐘
        this.needcheckcount = 120
      }
      this.initTimer()
    }
  },
  destroyed() {
    clearInterval(this.checktimer)
  },
  methods: {
    backtoh() {
      this.$router.push({ path: '/' })
    },
    checkPlatform() {
      return checkPlatform()
    },
    initTimer() {
      const self = this
      this.checktimer = setInterval(() => {
        if (this.apicount >= this.needcheckcount) {
          clearInterval(self.checktimer)
          self.apicount = 0
          self.$router.push({ path: '/order/fail' })
        } else if (this.payment && this.id) {
          getOrderstatus(this.payment, this.id)
            .then(res => {
              self.apicount += 1
              if (res.data.msg === 'success' && res.data.data.order_num) {
                clearInterval(self.checktimer)
                self.apicount = 0
                self.$router.push({
                  path: '/order/finish',
                  query: { order_num: res.data.data.order_num }
                })
              }
            })
            .catch(() => {
              self.apicount += 1
            })
        }
      }, this.perchecktime)
    },
    checkpayment() {
      const self = this
      getOrderstatus(this.payment, this.id).then(res => {
        if (res.data.msg === 'success' && res.data.data.order_num) {
          clearInterval(self.checktimer)
          self.apicount = 0
          self.$router.push({
            path: '/order/finish',
            query: { order_num: res.data.data.order_num }
          })
        } else if (res.data.msg === 'success' && !res.data.data) {
          showNotice('尚未完成付款,請完成付款流程!')
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import 'assets/scss/common.scss';
.qrcodearea {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .btnarea {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.ckpaid-wrapper {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
  position: relative;
  .snippet {
    margin-top: 10px;
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #26b7bc;
    color: #26b7bc;
    animation: dotFlashing 1s infinite;
  }
  .confirm-text {
    padding: 30px 0;
    font-weight: 700;
    font-size: 1.2rem;
    color: #545453;
  }
  .warning-text {
    letter-spacing: 2px;
    width: 50%;
    text-align: justify;
    color: #e32d2d;
    font-weight: bold;
  }
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #26b7bc;
  color: #26b7bc;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #26b7bc;
  color: #26b7bc;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #26b7bc;
  color: #26b7bc;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #26b7bc;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
</style>
